import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import MapContext from "../../../application/Context/MapContext";
import { SearchBarContainer } from "../shared-components/searchInput/search-input-styles";
import { SearchInput } from "../shared-components/searchInput/SearchInput";
import { useTranslation } from "react-i18next";
export var SearchBar = function () {
    var _a = React.useContext(MapContext), searchInput = _a.searchInput, handleSearchInput = _a.handleSearchInput, handleSearchTerm = _a.handleSearchTerm, handleKeyDown = _a.handleKeyDown;
    var t = useTranslation(["common"]).t;
    return (_jsx(SearchBarContainer, { children: _jsx(SearchInput, { placeholder: t("Rechercher dans le Réseau d'affaires nordiques"), type: "text", value: searchInput, onChange: handleSearchInput, handleSearch: handleSearchTerm, onKeyDown: handleKeyDown, id: "search-bar" }) }));
};
